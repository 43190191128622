<template>
    <div>
      <span class="HeadlineDetails">{{ x('display') }}:</span>&nbsp;
      <a @click="toggleView()" class="view_type"><i
        class="Theme_C1_Headline fa" :class="toggleButtonClass"/></a>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import DisplayModes from '@/enums/display-modes';
  import Views from '@/enums/views';
  import translate from '@/components/Mixins/Translate';

  export default {
    name: 'gallery-topbar-right',
    mixins: [translate],
    computed: {
      toggleButtonClass() {
        switch (this.displayModes[Views.Gallery]) {
          case DisplayModes.LargeCards:
            return 'fa-square-o';
          case DisplayModes.SmallCards:
            return 'fa-th-large';
          case DisplayModes.Insta:
            return 'fa-th';
          default:
            return 'fa-question-square'; // Error
        }
      },
      ...mapState(['displayModes']),
    },
    methods: {
      toggleView() {
        const displayMode = {};
        displayMode[Views.Gallery] = DisplayModes.nextMode(this.displayModes[Views.Gallery]);
        this.$store.commit('setDisplayMode', displayMode);
      },
    },
  };
</script>

<style scoped>

</style>
